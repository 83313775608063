import axios, { AxiosInstance } from "axios";

const NON_AUTH_BASE_URL = window.MLBBest.envVariables.NON_AUTH_BASE_URL;

const NonAuthClient: AxiosInstance = axios.create({
  headers: {
    Accept: "*/*",
  },
  baseURL: NON_AUTH_BASE_URL,
  withCredentials: true,
});

export default NonAuthClient;
