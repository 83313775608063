import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { AuthProvider } from "./AuthContext";
import { useDplTheme } from "./DplThemeContext";
import { LoadingProvider } from "./LoadingContext";
import { SiteAlertProvider } from "./SiteAlertContext";
import { DplAlertProvider } from "./DplAlertContext";
import { SavedSearchProvider } from "./SavedSearchContext";
import { MetadataProvider } from "./MetadataContext";
import HttpInterceptor from "../clients/HttpInterceptor";
import "video-react/dist/video-react.css";

const Providers: React.FC = ({ children }) => {
  const { theme } = useDplTheme();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <SiteAlertProvider>
        <LoadingProvider>
          <AuthProvider>
            <HttpInterceptor>
              <DplAlertProvider>
                <MetadataProvider>
                  <SavedSearchProvider>
                    <ThemeProvider theme={theme}>{children}</ThemeProvider>
                  </SavedSearchProvider>
                </MetadataProvider>
              </DplAlertProvider>
            </HttpInterceptor>
          </AuthProvider>
        </LoadingProvider>
      </SiteAlertProvider>
    </MuiPickersUtilsProvider>
  );
};

export default Providers;
